<!-- 发布通知 -->
<template>
  <div class="publish-notice-page">
    <!-- <TopWrapper :topObj="topObj" /> -->
    <van-form @failed="onFailed" class="main-wrapper" ref="publishNotice">
      <div class="select-box">
        <van-field
          v-model="naTitle"
          name="naTitle"
          label="标题"
          required
          placeholder="请入标题"
          maxlength="50"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          required
          readonly
          clickable
          name="dictCode"
          v-model="dictCode"
          :value="dictCode"
          label="发布类型"
          placeholder="请选择发布类型"
          @click="showPublishType = true"
          :rules="[{ required: true, message: '' }]"
        />
        <van-popup v-model="showPublishType" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="handleType"
            @cancel="showPublishType = false"
          />
        </van-popup>
        <van-field
          required
          name="topType"
          label="是否置顶"
          :rules="[{ required: true, message: '请选择是否置顶' }]"
        >
          <template #input>
            <van-radio-group v-model="topType" direction="horizontal">
              <!-- topType	是否置顶：0-否 1-是 -->
              <van-radio :name="0">否</van-radio>
              <van-radio :name="1">是</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          readonly
          v-if="topType == 1"
          required
          v-model="topDownTime"
          name="topDownTime"
          label="置顶失效时间"
          :rules="[{ required: true, message: '请选择置顶失效时间' }]"
          @click.stop="handleDownTime"
        >
        </van-field>
        <van-field
          required
          name="homePageType"
          label="是否主页加推"
          :rules="[{ required: true, message: '请选择是否主页加推' }]"
          v-if="curColumn && curColumn.code == 'red_file'"
        >
          <template #input>
            <van-radio-group
              :disabled="topType == 1"
              v-model="homePageType"
              direction="horizontal"
            >
              <!-- homePageType	是否主页加推：0-否 1-是 -->
              <van-radio :name="0">否</van-radio>
              <van-radio :name="1">是</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          is-link
          readonly
          placeholder="请选择接收公司/部门"
          @click.stop="show = true"
          name="fieldValue"
          v-model="fieldValue"
        >
          <template #label>接收公司/部门</template>
          <template #input v-if="fieldLen > 0">
            <span class="tag">{{ fieldValues[0] }}</span>
            <span class="tag">+&nbsp;{{ fieldLen }}</span>
          </template>
        </van-field>
        <p class="remak">*不选接收公司/部门默认发送全部</p>
        <van-popup
          v-model="show"
          position="bottom"
          :style="{ height: '50%' }"
          round
          closeable
          @close="closedPop"
        >
          <p class="title-popup">请选择接收公司/部门</p>
          <van-checkbox-group v-model="result">
            <van-cell-group>
              <van-cell
                v-for="(item, index) in options"
                clickable
                :key="index"
                :title="`${item.departmentName}`"
                @click.stop="toggle(index)"
              >
                <template #right-icon>
                  <van-checkbox
                    :name="item.departmentCode"
                    ref="checkboxes"
                    shape="square"
                  />
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
        </van-popup>
      </div>
      <div class="message-box">
        <p class="msg-title">
          <span>通知内容</span>
          <van-button
            round
            size="small"
            native-type="button"
            @click="editShow = true"
            >全屏</van-button
          >
        </p>

        <van-field
          v-model="message"
          :autosize="{ maxHeight: 500, minHeight: 300 }"
          label=""
          type="textarea"
          placeholder="请输入"
          :rules="[{ required: true, message: '' }]"
        >
          <template #input>
            <quillEditor
              v-model="message"
              maxlength="8000"
              :message="message"
              :readonly="false"
            ></quillEditor>
          </template>
        </van-field>
        <div class="upload-box">
          <span class="label">上传附件：</span>
          <div class="upload-file-box">
            <upload-file
              :limit="3"
              @input="changeInput"
              multiple
              :value="fileList"
            />
          </div>
        </div>
        <div class="btn-box">
          <van-button
            round
            size="small"
            native-type="button"
            type="info"
            @click="saveDraft"
            :loading="isloading"
            >保存为草稿</van-button
          >
          <van-button
            round
            size="small"
            native-type="button"
            type="info"
            @click="save"
            :loading="isloading"
            >发布</van-button
          >
          <van-button
            round
            size="small"
            native-type="button"
            type="info"
            :loading="isloading"
            @click="orderSave"
            >定时发布</van-button
          >
        </div>
      </div>
    </van-form>

    <van-popup title="请选择发布时间" v-model="showPicker" position="bottom">
      <van-form validate-first @failed="onFailed" ref="timeForm">
        <van-field
          readonly
          v-model="onlineTime"
          autosize
          label="发布时间"
          name="onlineTime"
          :rules="[{ required: true, message: '请选择发布时间' }]"
        />
        <van-button @click="onSure" round block type="info" native-type="button"
          >确认时间</van-button
        >
      </van-form>
      <van-datetime-picker
        :show-toolbar="false"
        type="datetime"
        v-model="publishTime"
        :min-date="minDate"
        @change="onChange"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup
      title="请选择置顶失效时间"
      v-model="showDownTime"
      position="bottom"
    >
      <van-form @failed="onFailed" v-if="showDownTime" ref="timeForm1">
        <van-field
          readonly
          v-model="topDownTime"
          autosize
          label="置顶失效时间"
          name="topDownTime"
          :rules="[{ required: true, message: '请选择置顶失效时间' }]"
        />
        <van-button
          @click="showDownTime = false"
          round
          block
          type="info"
          native-type="button"
          >确认时间</van-button
        >
      </van-form>
      <van-datetime-picker
        :show-toolbar="false"
        type="datetime"
        v-model="downTime"
        @change="onChangeDownTime"
        :min-date="minDate"
        @cancel="handleCloseDownTime"
      />
    </van-popup>

    <van-popup
      title="请选择上线时间"
      v-model="showOnlineTime1"
      position="bottom"
    >
      <van-form @failed="onFailed" v-if="showOnlineTime1" ref="onlineTimeForm1">
        <van-field
          readonly
          v-model="onlineTime1"
          autosize
          label="上线时间"
          name="onlineTime1"
          :rules="[{ required: true, message: '请选择上线时间' }]"
        />
        <van-button
          @click="showOnlineTime1 = false"
          round
          block
          type="info"
          native-type="button"
          >确认时间</van-button
        >
      </van-form>
      <van-datetime-picker
        :show-toolbar="false"
        type="datetime"
        v-model="onlineT1"
        @change="onChangeOnlineTime1"
        :min-date="minDate"
        @cancel="handleCloseOnlineTime1"
      />
    </van-popup>

    <van-popup v-model="editShow" class="editor">
      <div class="editor-box">
        <quillEditor
          class="quill-editor"
          v-model="message"
          maxlength="8000"
          :message="message"
          :readonly="false"
        ></quillEditor>
        <div class="btn-box">
          <div class="editor-button" @click="editShow = false">确定</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import UploadFile from "@/components/FormCreate/components/UploadFile/index.vue";
import { getAllList } from "@/api/addressList/index.js";
import { addNotice, getAllDictListByNotice } from "@/api/notice/index.js";
import quillEditor from "@/components/quillEditor/index.vue";

import TopWrapper from "@/components/topWrapper/index.vue";

import { Toast } from "vant";

export default {
  data() {
    return {
      showPublishType: false, //发布类型
      dictCode: "",
      columns: [],
      publishOptions: null,
      curColumn: null,
      topObj: {
        bgColor: "#fff",
        title: "发布通知",
        txtColor: "#000",
        isBack: true,
        isMsg: false,
      },
      editShow: false,
      show: false,
      fieldValue: "",
      fieldValues: [],
      fieldLen: 0,
      result: [],
      naTitle: "",
      topType: null,
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [],
      message: "",
      publishTime: "",
      onlineTime: "",
      showPicker: false,
      count: 2,
      timer: null,
      showDownTime: false,
      topDownTime: "",
      downTime: new Date(),
      minDate: new Date(),
      isloading: false,
      fileList: [],
      homePageType: null,
      onlineTime1: "",
      showOnlineTime1: false,
      onlineT1: "",
    };
  },
  watch: {
    topType: {
      handler(val) {
        if (val) {
          this.homePageType = 1;
        }
      },
      immediate: true,
    },
  },
  components: { TopWrapper, UploadFile, quillEditor },

  computed: {},

  mounted() {
    this.getDepartment();
    this.getDics();
  },

  methods: {
    getContent(content) {
      this.message = content;
    },
    handleType(value) {
      this.curColumn = this.publishOptions.find((e) => {
        if (value == e.dictValue) {
          return e;
        }
      });
      if (this.curColumn.code != "red_file") {
        this.homePageType = "0";
      }
      this.dictCode = value;
      this.showPublishType = false;
    },
    /* 获取类型 */
    getDics() {
      getAllDictListByNotice().then((res) => {
        let { data } = res;
        this.publishOptions = data;
        if (data) {
          this.columns = data.map((e) => {
            return e.dictValue;
          });
          if (this.$route.query) {
            let { id } = this.$route.query;
            if (id) {
              this.getNoticeInfo(id);
            }
          }
        }
      });
    },
    changeInput(list) {
      this.fileList = list;
    },
    handleCloseDownTime() {
      this.topDownTime = this.downTime;
      this.showDownTime = false;
    },
    handleDownTime(e) {
      this.topDownTime = this.$d(new Date(this.downTime)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.showDownTime = true;
    },
    handleOnlineTime1() {
      this.onlineTime1 = this.$d(new Date(this.downTime)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.onlineT1 = this.onlineTime1;

      this.showOnlineTime1 = true;
    },
    onChangeDownTime(picker) {
      let val =
        picker.getValues()[0] +
        "-" +
        picker.getValues()[1] +
        "-" +
        picker.getValues()[2] +
        " " +
        picker.getValues()[3] +
        ":" +
        picker.getValues()[4] +
        ":00";
      this.downTime = val;
      this.topDownTime = val;
    },
    handleCloseOnlineTime1() {
      this.onlineTime1 = this.onlineT1;
      this.showOnlineTime1 = false;
    },

    onChange(picker) {
      let val =
        picker.getValues()[0] +
        "-" +
        picker.getValues()[1] +
        "-" +
        picker.getValues()[2] +
        " " +
        picker.getValues()[3] +
        ":" +
        picker.getValues()[4] +
        ":00";
      this.publishTime = val;
      this.onlineTime = val;
    },
    onSubmit(val) {
      //   @ApiModelProperty(naType = "1-草稿 2-已发布 3-已下线")
      // console.log(this.topType, "topType");
      this.isloading = true;
      const params = {
        sourceType: "APP_OA",
        naTitle: this.naTitle,
        naContent: this.message,
        homePageType: this.homePageType,
        topType: this.topType,
        departmentCodes: this.result.toString(),
        topDownTime: this.topDownTime,
        onlineTime: val == 3 ? this.onlineTime : this.onlineTime1,
        // onlineTime: this.onlineTime,
        dictCode: this.curColumn ? this.curColumn.bizCode : "",
        naType: val == 1 ? 1 : 2,
        isPre: val == 3 ? "1" : "0", //是否定时发布0-否1-是
        attachmentUrl: this.fileList
          .map((e) => {
            return e.objectKey;
          })
          .toString(),
      };
      addNotice(params)
        .then((res) => {
          this.isloading = false;
          if (res) {
            let { code, msg } = res;
            if (code == 200) {
              Toast.success(msg);
              this.showPicker = false;
              this.timer = setInterval(() => {
                if (this.count > 0) {
                  this.count--;
                } else {
                  clearInterval(this.timer);
                  this.timer = null;
                  //跳转的页面写在此处
                  this.$router.push({ path: "/home" });
                }
              }, 1000);
            }
          }
        })
        .catch((e) => {
          this.isloading = false;
        });
    },
    /* 失败 */
    onFailed() {},
    save() {
      this.$refs.publishNotice
        .validate(["naTitle", "topType", "message", "topDownTime", "dictCode"])
        .then((res) => {
          this.onSubmit(2);
        });
    },
    orderSave() {
      this.$refs.publishNotice
        .validate(["naTitle", "topType", "message", "topDownTime", "dictCode"])
        .then((res) => {
          this.onlineTime = this.$d(new Date()).format("YYYY-MM-DD HH:mm:ss");
          this.showPicker = true;
        });
    },
    saveDraft() {
      this.$refs.publishNotice
        .validate(["naTitle", "topType", "message", "topDownTime", "dictCode"])
        .then((res) => {
          this.onSubmit(1);
        });
    },
    onSure() {
      this.$refs.timeForm.validate("onlineTime").then((res) => {
        this.onSubmit(3);
      });
    },
    closedPop() {
      let names = [];
      this.result.forEach((item) => {
        this.options.find((e) => {
          if (e.departmentCode === item) {
            names.push(e.departmentName);
          }
        });
      });

      this.fieldValue = names.toString();
      this.fieldValues = names;
      this.fieldLen = names.length;
      this.show = false;
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    getDepartment() {
      getAllList().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.options = data;
          }
        }
      });
    },
    onChangeOnlineTime1(picker) {
      let val =
        picker.getValues()[0] +
        "-" +
        picker.getValues()[1] +
        "-" +
        picker.getValues()[2] +
        " " +
        picker.getValues()[3] +
        ":" +
        picker.getValues()[4] +
        ":00";
      this.onlineT1 = val;
      this.onlineTime1 = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.publish-notice-page {
  width: 100%;
  height: auto;
  min-height: 100%;

  background: #fafafa;
  position: relative;
  overflow: hidden;
  .main-wrapper {
    overflow-y: auto;
    height: auto;
    .select-box {
      margin-top: 0.3rem;
      /deep/ .van-cell {
        padding: 0.3rem 0.3rem;

        .van-field__label {
          font-weight: 400;
          color: #333333;
          font-size: 0.26rem;
          width: 1.65rem;
        }
        .tag {
          background-color: #f4f4f5;
          border-color: #e9e9eb;
          color: #909399;
          padding: 0.08rem;
          line-height: 0.22rem;
          margin: 0.02rem 0 0.02rem 0.06rem;
        }
      }
      .title-popup {
        font-weight: 700;
        font-size: 0.28rem;
        line-height: 1rem;
        /* height: 0.48rem; */
        padding: 0 0.3rem;
        letter-spacing: 0.01rem;
      }
      .remak {
        background: #fff;
        padding: 0.3rem;
        color: red;
      }
    }
    .message-box {
      margin-top: 0.3rem;
      background: #fff;
      padding: 0.3rem;
      .msg-title {
        font-size: 0.26rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.37rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      /deep/ .van-field {
        margin-top: 0.3rem;
        width: 6.9rem;
        min-height: 5rem;
        border-radius: 0.16rem;
        border: none;
      }
      .van-cell::after {
        border: none;
      }
      // /deep/ textarea {
      //   min-height: 10rem;
      //   height: auto;
      // }
      .upload-box {
        background: #fff;
        padding: 0.3rem;
        display: flex;
        flex-direction: row;
        // align-items: center;
        .label {
          width: 1.5rem;
          font-size: 0.2rem;
          text-align: center;
        }
        .upload-file-box {
          flex: 1;
          width: 4rem;
        }
      }
      .btn-box {
        width: 100%;
        margin-top: 0.3rem;
        // background: #fff;
        // padding: 0.3rem;
        // // position: absolute;
        // bottom: 0.2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        /deep/ .van-button--info {
          min-width: 1.5rem;
        }
      }
    }
  }
}
.editor {
  width: 100%;
  height: 100%;
  .editor-box {
    height: 100%;
    /deep/.quill-editor {
      height: calc(100% - 1.5rem);
      .ql-container {
        height: calc(100% - 66px);
        .ql-editor {
        }
      }
    }
  }
  .btn-box {
    padding: 0 0.2rem;
    display: flex;
    justify-content: flex-end;
    .editor-button {
      width: 2.16rem;
      height: 0.8rem;
      background: #e5f4fd;
      border-radius: 0.4rem;
      color: #ffffff;
      font-size: 0.26rem;
      color: #0094ee;
      line-height: 0.8rem;
      text-align: center;
    }
  }
}
</style>
